<template>
  <div class="plan-info">
    <div class="info">
      <div class="title">内容信息</div>
      <div class="cnt">
        <el-form :model="form" >
          <el-form-item label='方案名称' >{{programName}}</el-form-item>
          <el-form-item label='关联方案名称' >{{form.oldProgramName}}</el-form-item>
          <el-form-item label='发布日期' >{{form.createTime}}</el-form-item>
          <el-form-item label='发布人数' >{{number}}</el-form-item>
          <el-form-item label='方案开始时间' >{{form.executionTime}}</el-form-item>
          <el-form-item label='方案结束时间' >{{form.endTime}}</el-form-item>
          <el-form-item label='具体内容' >
            <el-table :data='form.programActivitys' border>
              <el-table-column   type='index'></el-table-column>
              <el-table-column  label="方案活动" prop='activityName'></el-table-column>
              <el-table-column  label="描述" prop='activityDescription'></el-table-column>
              <el-table-column  label="是否周期" width="80px">
                <template slot-scope="scope">
                  {{scope.row.activityMode==1?'否':'是'}}
                </template>
              </el-table-column>
              <el-table-column  label="主题内容"  width="600px" >
                <template slot-scope="scope">
                  
                    <template v-if='scope.row.activityType==1'>
                    <el-table :data='scope.row.drugsManages' :border='true'> 
                      <el-table-column prop='drugsSimpleName' label='药品简称'></el-table-column>
                      <el-table-column prop='drugsUseTypeName' label='用法'></el-table-column>
                      <el-table-column prop='drugsBaseDose' label='用量'></el-table-column>
                      <el-table-column prop='drugsUseUnitName' label='单位'></el-table-column>
                      
                    </el-table>
                    </template>
                    <template v-else-if='scope.row.activityType==4'>
                      <el-table :data='scope.row.missionaryWorks' :border='true'> 
                        <el-table-column prop='workTypeName' label='宣教分类'></el-table-column>
                        <el-table-column prop='workName' label='宣教名称'></el-table-column>
                        
                      </el-table>
                    </template>
                    <template v-else-if='scope.row.activityType==5'>
                      <el-table :data='scope.row.indexLibraries' :border='true'> 
                        <el-table-column prop='libraryTypeName' label='随访分类'></el-table-column>
                        <el-table-column prop='libraryName' label='随访名称'></el-table-column>
                        
                      </el-table>
                    
                  </template>
                   <template v-else>
                      
                    
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="list">
      <div class="title">
        患者信息
      </div>
        <el-table :data='data' border>
          <!-- <el-table-column type='index'></el-table-column> -->
          <el-table-column  label="姓名" prop='patienceName'></el-table-column>
          <el-table-column  label="状态" prop='executionStatus'>
            <template slot-scope="scope">
              <div v-if='scope.row.executionStatus===1'>未开始</div>
              <div v-else-if='scope.row.executionStatus===2'>进行中</div>
              <div v-else-if='scope.row.executionStatus===3'>已结束</div>
            </template>
          </el-table-column>
        </el-table>

    </div>
  </div>
</template>
<script>
export default {
  name:'feedBackPlanInfo',
  data(){
    return {
      number:'',
      dataListLoading:false,
      form:{},
      data:[],
      programName:''
    }
  },
  mounted() {
    let query = this.$route.query
      this.number = query.num
    if(query.id){
      this.dataListLoading = true
       this.init(query.id)
    }
  },
  methods:{
    async init(id){
      const {data:res} = await this.$http({
        url:this.$http.adornUrl('/patienceProgram/info'),
        method:'get',
        params:{
          programId:id
        }
      })
      console.log(res,'res info');
      if(res.status){
        this.form = res.data[0]
        this.data = res.data
          let name = ''
        res.data.forEach(v=>{
          name+= v.programName + '，'
        })
        
        this.programName = name.slice(0,name.length-1)
      }else{
        this.$message.error('获取失败，请重试')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.plan-info{
  .form{
    height: auto;
  }
  display: flex;
  justify-content: space-between;
  .title{
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    background-color: #e4e4e4;
    text-indent: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

  }
  .info{
    flex-basis: 70%;
    min-height: 700px;
    box-shadow:0 0 8px 0 rgba(232,237,250,.6),0 2px 4px 0 rgba(232,237,250,.5);
    border-radius: 5px;
    .cnt{
      padding: 20px;
    }
  }
  .list{
    flex-basis: 28%;
    min-height: 700px;
    box-shadow:0 0 8px 0 rgba(232,237,250,.6),0 2px 4px 0 rgba(232,237,250,.5);
    border-radius: 5px;

  }
}
</style>